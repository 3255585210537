import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163')
];

export const server_loads = [];

export const dictionary = {
		"/": [60],
		"/(admin)/admin/(web-leads)": [~64,[2,5],[3]],
		"/(admin)/admin/analytics": [68,[2,6],[3]],
		"/(admin)/admin/analytics/chart-months": [69,[2,6],[3]],
		"/(admin)/admin/analytics/chart-week": [70,[2,6],[3]],
		"/(admin)/admin/classes/(classes)": [71,[2,7],[3]],
		"/(admin)/admin/classes/[class_id]": [72,[2,8],[3]],
		"/(admin)/admin/classes/[class_id]/booking": [73,[2,8],[3]],
		"/(admin)/admin/classes/[class_id]/cadence": [74,[2,8],[3]],
		"/(admin)/admin/classes/[class_id]/clients": [75,[2,8],[3]],
		"/(admin)/admin/classes/[class_id]/details": [76,[2,8,9],[3]],
		"/(admin)/admin/classes/[class_id]/lessons": [77,[2,8],[3]],
		"/(admin)/admin/classes/[class_id]/students": [78,[2,8],[3]],
		"/(admin)/admin/clients/(clients)": [79,[2,10],[3]],
		"/(admin)/admin/clients/[client_id]": [80,[2,11],[3]],
		"/(admin)/admin/clients/[client_id]/account-statement": [81,[2,11],[3]],
		"/(admin)/admin/clients/[client_id]/classes": [82,[2,11],[3]],
		"/(admin)/admin/clients/[client_id]/financing": [83,[2,11],[3]],
		"/(admin)/admin/clients/[client_id]/lessons": [84,[2,11],[3]],
		"/(admin)/admin/clients/[client_id]/make": [85,[2,11],[3]],
		"/(admin)/admin/clients/[client_id]/notes": [86,[2,11],[3]],
		"/(admin)/admin/clients/[client_id]/payments": [87,[2,11],[3]],
		"/(admin)/admin/clients/[client_id]/registrations": [88,[2,11],[3]],
		"/(admin)/admin/clients/[client_id]/students": [89,[2,11],[3]],
		"/(admin)/admin/clients/[client_id]/wallet": [90,[2,11],[3]],
		"/(admin)/admin/dashboard": [91,[2,12],[3]],
		"/(admin)/admin/(web-leads)/incomplete": [65,[2,5],[3]],
		"/(admin)/admin/lessons": [92,[2],[3]],
		"/(admin)/admin/login": [93,[2],[3]],
		"/(admin)/admin/(web-leads)/paid": [66,[2,5],[3]],
		"/(admin)/admin/phone-leads/(leads)": [94,[2,13],[3]],
		"/(admin)/admin/phone-leads/(leads)/all": [95,[2,13],[3]],
		"/(admin)/admin/phone-leads/(leads)/paid": [96,[2,13],[3]],
		"/(admin)/admin/phone-leads/(leads)/unpaid": [97,[2,13],[3]],
		"/(admin)/admin/phone-leads/[lead_id]": [98,[2,14],[3]],
		"/(admin)/admin/registrations/(registrations)": [99,[2,15],[3]],
		"/(admin)/admin/requests": [100,[2],[3]],
		"/(admin)/admin/settings/assign-rules/(assign-rules)": [101,[2,16],[3]],
		"/(admin)/admin/settings/assign-rules/[assign_rule_id]": [102,[2,17],[3]],
		"/(admin)/admin/settings/class-rooms/(class-rooms)": [103,[2,18],[3]],
		"/(admin)/admin/settings/class-rooms/[room_id]": [104,[2,19],[3]],
		"/(admin)/admin/settings/client-statuses/(client-statuses)": [105,[2,20],[3]],
		"/(admin)/admin/settings/client-statuses/[client_status_id]": [106,[2,21],[3]],
		"/(admin)/admin/settings/curriculum-lessons/(curriculum-lessons)": [107,[2,22],[3]],
		"/(admin)/admin/settings/curriculum-modules/(curriculum-modules)": [108,[2,23],[3]],
		"/(admin)/admin/settings/curriculum-modules/[curriculum_module_id]": [109,[2,24],[3]],
		"/(admin)/admin/settings/curriculum-modules/[curriculum_module_id]/curriculum-lessons": [110,[2,24],[3]],
		"/(admin)/admin/settings/exports": [111,[2,25],[3]],
		"/(admin)/admin/settings/leads/(leads)": [112,[2,26],[3]],
		"/(admin)/admin/settings/locations/(locations)": [113,[2,27],[3]],
		"/(admin)/admin/settings/locations/[location_id]": [114,[2,28],[3]],
		"/(admin)/admin/settings/mail-logs": [115,[2,29],[3]],
		"/(admin)/admin/settings/markets/(markets)": [116,[2,30],[3]],
		"/(admin)/admin/settings/markets/[market_id]": [117,[2,31],[3]],
		"/(admin)/admin/settings/notes/(notes)": [118,[2,32],[3]],
		"/(admin)/admin/settings/notification-settings/(notification-settings)": [119,[2,33],[3]],
		"/(admin)/admin/settings/offerings/(offerings)": [120,[2,34],[3]],
		"/(admin)/admin/settings/offerings/[offering_id]": [121,[2,35],[3]],
		"/(admin)/admin/settings/offerings/[offering_id]/booking": [122,[2,35],[3]],
		"/(admin)/admin/settings/offerings/[offering_id]/bundles": [123,[2,35],[3]],
		"/(admin)/admin/settings/payments/transactions": [124,[2,36],[3]],
		"/(admin)/admin/settings/payroll/(payroll)": [125,[2,37],[3]],
		"/(admin)/admin/settings/payroll/[payroll_id]": [126,[2,38],[3]],
		"/(admin)/admin/settings/payroll/[payroll_id]/additional": [127,[2,38],[3]],
		"/(admin)/admin/settings/payroll/[payroll_id]/payment-batches": [128,[2,38],[3]],
		"/(admin)/admin/settings/payroll/[payroll_id]/tutor-pay": [129,[2,38],[3]],
		"/(admin)/admin/settings/payroll/[payroll_id]/tutors-payroll": [130,[2,38],[3]],
		"/(admin)/admin/settings/pricing-profiles": [131,[2],[3]],
		"/(admin)/admin/settings/schools/(schools)": [132,[2,39],[3]],
		"/(admin)/admin/settings/semesters/(semesters)": [133,[2,40],[3]],
		"/(admin)/admin/settings/semesters/[semester_id]": [134,[2,41],[3]],
		"/(admin)/admin/settings/sources/(sources)": [135,[2,42],[3]],
		"/(admin)/admin/settings/sources/[source_id]": [136,[2,43],[3]],
		"/(admin)/admin/settings/status-colors/(status-colors)": [137,[2,44],[3]],
		"/(admin)/admin/settings/status-colors/[status_color_id]": [138,[2,45],[3]],
		"/(admin)/admin/settings/tickets/(tickets)": [139,[2],[3]],
		"/(admin)/admin/settings/users/(users)": [140,[2,46],[3]],
		"/(admin)/admin/settings/users/[user_id]": [141,[2,47],[3]],
		"/(admin)/admin/students-lessons/(students-lessons)": [143,[2,49],[3]],
		"/(admin)/admin/students/(students)": [142,[2,48],[3]],
		"/(admin)/admin/tutor-analytics": [144,[2,50],[3]],
		"/(admin)/admin/tutor-teams/(tutor-teams)": [145,[2,51],[3]],
		"/(admin)/admin/tutor-teams/[tutor_team_id]": [146,[2,52],[3]],
		"/(admin)/admin/tutor-teams/[tutor_team_id]/tutors": [147,[2,52],[3]],
		"/(admin)/admin/tutors/(tutors)": [148,[2,53],[3]],
		"/(admin)/admin/tutors/schedules-daily": [159,[2,56],[3]],
		"/(admin)/admin/tutors/schedules-new-view": [160,[2,57],[3]],
		"/(admin)/admin/tutors/schedules": [158,[2,55],[3]],
		"/(admin)/admin/tutors/[tutor_id]": [149,[2,54],[3]],
		"/(admin)/admin/tutors/[tutor_id]/additional": [150,[2,54],[3]],
		"/(admin)/admin/tutors/[tutor_id]/analytics": [151,[2,54],[3]],
		"/(admin)/admin/tutors/[tutor_id]/availability": [152,[2,54],[3]],
		"/(admin)/admin/tutors/[tutor_id]/notes": [153,[2,54],[3]],
		"/(admin)/admin/tutors/[tutor_id]/payrate-overrides": [154,[2,54],[3]],
		"/(admin)/admin/tutors/[tutor_id]/payrates": [155,[2,54],[3]],
		"/(admin)/admin/tutors/[tutor_id]/teams": [156,[2,54],[3]],
		"/(admin)/admin/tutors/[tutor_id]/unavailability": [157,[2,54],[3]],
		"/(admin)/admin/(web-leads)/without-payment": [67,[2,5],[3]],
		"/(admin)/admin/(web-lead)/[lead_id]": [61,[2,4],[3]],
		"/(admin)/admin/(web-lead)/[lead_id]/brick": [62,[2,4],[3]],
		"/(admin)/admin/(web-lead)/[lead_id]/payments": [63,[2,4],[3]],
		"/(admin)/dev/a": [161,[58]],
		"/(admin)/dev/b": [162,[58]],
		"/(tutor)/tutor/pay/[encrypted_tutor_id]": [163,[59]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';