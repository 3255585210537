import { dev } from '$app/environment';
import { PUBLIC_SENTRY_MODE } from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';


if (!dev) {
	Sentry.init({
		dsn: 'https://bc633673f42425ac9558e630d45907b5@o4506003731382272.ingest.sentry.io/4506026071556096',

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,

		// Optional: Initialize Session Replay:
		integrations: [
			new Sentry.Replay()
		],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		environment: PUBLIC_SENTRY_MODE,
		beforeSend(event, hint) {
			const error = hint.originalException as any;

			if (error?.message?.includes('"ignoreInSentry":true') || error?.ignoreInSentry) return null;

			return event;
		}
	});
}

export const handleError = Sentry.handleErrorWithSentry();
